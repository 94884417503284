var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("Monthly Data")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "monthly" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _vm._v(
        "取得期間： " + _vm._s(_vm.startDate) + "〜" + _vm._s(_vm.endDate)
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _c("h3", [_vm._v("月別推移")]),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.headers, function (h, i) {
              return _c("th", {
                key: "header-" + i,
                domProps: { innerHTML: _vm._s(h) },
              })
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.results, function (d, i) {
            return _c(
              "tr",
              { key: "result-parent-" + i },
              _vm._l(d, function (item, j) {
                return _c("td", { key: "result-child-" + j }, [
                  _vm._v(_vm._s(item)),
                ])
              }),
              0
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }