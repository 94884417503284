var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import JsonExportButton from '~/js/components/atomos/JsonExportButton.vue';
// TODO: サブチャンネルがあったときはどうするか？
var Information = /** @class */ (function (_super) {
    __extends(Information, _super);
    function Information() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = '';
        _this.description = '';
        _this.url = '';
        _this.thumb = null;
        _this.movies = '';
        _this.subscription = '';
        _this.total = '';
        _this.json = null;
        return _this;
    }
    Information.prototype.created = function () {
        var _this = this;
        gapi.client.load('youtube', 'v3', function () {
            var options = {
                mine: true,
                part: 'snippet,contentDetails,statistics'
            };
            var request = gapi.client.youtube.channels.list(options);
            request.execute(function (response) {
                console.log('==== Information ====');
                console.log(response);
                console.log('==== /Information ====');
                _this.json = {
                    request: 'gapi.client.youtube.channels.list',
                    options: options,
                    result: response.result
                };
                var _a = response
                    .items[0], snippet = _a.snippet, statistics = _a.statistics;
                _this.name = snippet.title;
                _this.description = snippet.description;
                _this.url = snippet.customUrl;
                _this.thumb = snippet.thumbnails.default;
                _this.movies = statistics.videoCount;
                _this.subscription = statistics.subscriberCount;
                _this.total = statistics.viewCount;
            });
        });
    };
    Information = __decorate([
        Component({
            components: { JsonExportButton: JsonExportButton }
        })
    ], Information);
    return Information;
}(Vue));
export default Information;
