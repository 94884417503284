import { render, staticRenderFns } from "./AdDataMovie.vue?vue&type=template&id=c922bbbc&scoped=true&lang=pug&"
import script from "./AdDataMovie.vue?vue&type=script&lang=ts&"
export * from "./AdDataMovie.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c922bbbc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/arc-one/_www/millsta-ready/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c922bbbc')) {
      api.createRecord('c922bbbc', component.options)
    } else {
      api.reload('c922bbbc', component.options)
    }
    module.hot.accept("./AdDataMovie.vue?vue&type=template&id=c922bbbc&scoped=true&lang=pug&", function () {
      api.rerender('c922bbbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/templates/AdDataMovie.vue"
export default component.exports