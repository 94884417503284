var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("広告収入(推定) 動画 Top 10")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "ad-data-movie" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _vm._v(
        "取得期間： " + _vm._s(_vm.startDate) + "〜" + _vm._s(_vm.endDate)
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.status === "forbidden"
          ? [_c("p", [_vm._v("収益化が通ってません")])]
          : _vm.status === "success"
          ? [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.result, function (item, i) {
                    return _c("tr", { key: "aaa-" + i }, [
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://youtu.be/" + item[0],
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(item[0]))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item[1]) + " Views")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("$" + _vm._s(item[2]))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("動画")]),
        _vm._v(" "),
        _c("th", [_vm._v("視聴数")]),
        _vm._v(" "),
        _c("th", [_vm._v("推定売上高")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }