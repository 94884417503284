var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import JsonExportButton from '~/js/components/atomos/JsonExportButton.vue';
import { dateFormat } from '~/js/modules/DateOperation';
var AdData = /** @class */ (function (_super) {
    __extends(AdData, _super);
    function AdData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.start = '2021-01-01';
        _this.today = '2021-12-31';
        _this.json = null;
        return _this;
    }
    Object.defineProperty(AdData.prototype, "startDate", {
        get: function () {
            return dateFormat(this.start, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdData.prototype, "endDate", {
        get: function () {
            return dateFormat(this.today, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdData.prototype, "status", {
        get: function () {
            if (this.json == null)
                return 'in progress';
            if (this.json.result === undefined)
                return 'forbidden';
            return 'success';
        },
        enumerable: false,
        configurable: true
    });
    AdData.prototype.created = function () {
        var _this = this;
        // https://developers.google.com/youtube/analytics/dimensions?hl=en#adType
        // https://developers.google.com/youtube/analytics/sample-requests#channel-revenue-ads-reports
        gapi.client.load('youtubeAnalytics', 'v2', function () {
            var options = {
                ids: 'channel==MINE',
                startDate: dateFormat(_this.start, 'yyyy-MM-01'),
                endDate: dateFormat(_this.today, 'yyyy-MM-31'),
                metrics: 'views,estimatedRevenue,estimatedAdRevenue,estimatedRedPartnerRevenue,grossRevenue,adImpressions,cpm,playbackBasedCpm,monetizedPlaybacks'
            };
            var reports = gapi.client.youtubeAnalytics.reports.query(options);
            reports.execute(function (response) {
                console.log('==== Ad ====');
                console.log(response);
                console.log('==== /Ad ====');
                _this.json = {
                    request: 'gapi.client.youtubeAnalytics.reports.query',
                    options: options,
                    result: response.result
                };
            });
        });
    };
    AdData = __decorate([
        Component({
            components: { JsonExportButton: JsonExportButton }
        })
    ], AdData);
    return AdData;
}(Vue));
export default AdData;
