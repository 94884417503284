var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
var JsonExportButton = /** @class */ (function (_super) {
    __extends(JsonExportButton, _super);
    function JsonExportButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.json = null;
        return _this;
    }
    Object.defineProperty(JsonExportButton.prototype, "disabled", {
        get: function () {
            return this.json === null;
        },
        enumerable: false,
        configurable: true
    });
    JsonExportButton.prototype.download = function () {
        var blob = new Blob([JSON.stringify(this.json, null, 2)], {
            type: 'application/json'
        });
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.download = this.filename + ".json";
        a.href = url;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    };
    __decorate([
        Prop({ type: Object, default: null })
    ], JsonExportButton.prototype, "json", void 0);
    __decorate([
        Prop({ type: String, required: true })
    ], JsonExportButton.prototype, "filename", void 0);
    JsonExportButton = __decorate([
        Component({})
    ], JsonExportButton);
    return JsonExportButton;
}(Vue));
export default JsonExportButton;
