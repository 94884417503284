var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("広告収入(推定)")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "ad-data" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _vm._v(
        "取得期間： " + _vm._s(_vm.startDate) + "〜" + _vm._s(_vm.endDate)
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.status === "forbidden"
          ? [_c("p", [_vm._v("収益化が通ってません")])]
          : _vm.status === "success"
          ? [_c("p", [_vm._v("収益化が通ってます")])]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }