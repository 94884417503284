var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("Age Group, Gender")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "age-group" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _vm._v(
        "取得期間： " + _vm._s(_vm.startDate) + "〜" + _vm._s(_vm.endDate)
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.results.length > 0
          ? [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.results, function (d, i) {
                    return _c("tr", { key: "result-parent-" + i }, [
                      _c("td", [_vm._v(_vm._s(_vm._f("age")(d.age)))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(d.female))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(d.male))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          : [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("集計に必要なデータが存在しないです"),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v("女性")]),
        _vm._v(" "),
        _c("th", [_vm._v("男性")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }