var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("ライブ配信リスト")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "live-broadcasts-list" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "mb-0" }, [
      _vm._v(
        "フィルターや日時を指定できないようなので、最新順から取ってきているだけのような気がする"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.status === "forbidden"
          ? [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("ライブ配信権限がありません"),
              ]),
            ]
          : _vm.status === "success"
          ? [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.result, function (item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://youtu.be/" + item.id,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(item.snippet.title))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("img", {
                          attrs: {
                            src: item.snippet.thumbnails.default.url,
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.snippet.liveChatId))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.displayStatus(item.status.lifeCycleStatus))
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("タイトル")]),
        _vm._v(" "),
        _c("th", [_vm._v("サムネイル")]),
        _vm._v(" "),
        _c("th", [_vm._v("ライブチャットID")]),
        _vm._v(" "),
        _c("th", [_vm._v("配信ステータス")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }