var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "apps" }, [
    _c("div", { staticClass: "pa-4" }, [
      _c("h1", [_vm._v("Own YouTube Data")]),
      _vm._v(" "),
      _vm.channelID
        ? _c("div", { staticClass: "pb-12" }, [
            _c("div", { staticClass: "pt-6" }, [_c("information")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("monthly")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("age-group")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("ranking")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("ranking-views")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("ranking-likes")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("ranking-comments")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("ad-data")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("ad-data-movie")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("live-broadcasts")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("live-streams")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("super-chat-events")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("members")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "pt-12" }, [_c("memberships-levels")], 1),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }