var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("人気動画 (閲覧数)")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "ranking-views" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _vm._v(
        "取得期間： " + _vm._s(_vm.startDate) + "〜" + _vm._s(_vm.endDate)
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-4" }, [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.comments, function (item, i) {
            return _c("tr", { key: "comments-" + item.id }, [
              _c("td", [_vm._v(_vm._s(i + 1) + "位")]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://youtu.be/" + item.id,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("img", { attrs: { src: item.thumbnail, alt: "" } }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.publishedAt))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.views))]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("順位")]),
        _vm._v(" "),
        _c("th", [_vm._v("動画")]),
        _vm._v(" "),
        _c("th", [_vm._v("サムネイル")]),
        _vm._v(" "),
        _c("th", [_vm._v("公開日")]),
        _vm._v(" "),
        _c("th", [_vm._v("閲覧数")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }