var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import JsonExportButton from '~/js/components/atomos/JsonExportButton.vue';
import { dateFormat, dateView } from '~/js/modules/DateOperation';
var Ranking = /** @class */ (function (_super) {
    __extends(Ranking, _super);
    function Ranking() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.start = '2021-01-01';
        _this.today = '2021-12-31';
        _this.populars = [];
        _this.popularJson = [];
        return _this;
    }
    Object.defineProperty(Ranking.prototype, "json", {
        get: function () {
            if (this.popularJson.length < 2)
                return null;
            return {
                popular: this.popularJson
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Ranking.prototype, "startDate", {
        get: function () {
            return dateFormat(this.start, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Ranking.prototype, "endDate", {
        get: function () {
            return dateFormat(this.today, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Ranking.prototype.created = function () {
        var _this = this;
        gapi.client.load('youtubeAnalytics', 'v2', function () {
            var options = {
                ids: 'channel==MINE',
                startDate: _this.start,
                endDate: _this.today,
                maxResults: 5,
                dimensions: 'video',
                metrics: 'estimatedMinutesWatched,views,likes,subscribersGained',
                sort: '-estimatedMinutesWatched'
            };
            var reports = gapi.client.youtubeAnalytics.reports.query(options);
            reports.execute(function (response) {
                console.log('==== Ranking (estimatedMinutesWatched) ====');
                console.log(response);
                console.log('==== /Ranking (estimatedMinutesWatched) ====');
                _this.popularJson.push({
                    request: 'gapi.client.youtubeAnalytics.reports.query',
                    options: options,
                    result: response.result
                });
                _this._movieData(response.rows, 'popular');
            });
        });
    };
    Ranking.prototype._movieData = function (data, type) {
        var _this = this;
        var ids = [];
        data.forEach(function (item) {
            ids.push(item[0]);
        });
        gapi.client.load('youtube', 'v3', function () {
            var options = {
                part: 'snippet,recordingDetails',
                maxResult: 5,
                id: ids.join(',')
            };
            var request = gapi.client.youtube.videos.list(options);
            request.execute(function (response) {
                console.log('==== Ranking (estimatedMinutesWatched) - Videos ====');
                console.log(response);
                console.log('==== /Ranking (estimatedMinutesWatched) - Videos ====');
                var res = response.items;
                switch (type) {
                    case 'popular':
                        _this._popularRanking(data, res);
                        _this.popularJson.push({
                            request: 'gapi.client.youtube.videos.list',
                            options: options,
                            result: response.result
                        });
                        break;
                    case 'live':
                        break;
                    default:
                        break;
                }
            });
        });
    };
    Ranking.prototype._popularRanking = function (data, movies) {
        var _this = this;
        data.forEach(function (d) {
            var movie = movies.find(function (movie) { return movie.id === d[0]; });
            _this.populars.push({
                id: d[0],
                title: movie.snippet.title,
                publishedAt: dateView(movie.snippet.publishedAt),
                thumbnail: movie.snippet.thumbnails.default.url,
                watched: d[1] + " \u5206",
                likes: d[2],
                subscribe: d[3] + " \u4EBA"
            });
        });
    };
    Ranking = __decorate([
        Component({
            components: { JsonExportButton: JsonExportButton }
        })
    ], Ranking);
    return Ranking;
}(Vue));
export default Ranking;
