var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import JsonExportButton from '~/js/components/atomos/JsonExportButton.vue';
var MembershipsLevels = /** @class */ (function (_super) {
    __extends(MembershipsLevels, _super);
    function MembershipsLevels() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.json = null;
        _this.result = [];
        return _this;
    }
    Object.defineProperty(MembershipsLevels.prototype, "status", {
        get: function () {
            if (this.json == null)
                return 'in progress';
            if (this.json.result === undefined)
                return 'forbidden';
            return 'success';
        },
        enumerable: false,
        configurable: true
    });
    MembershipsLevels.prototype.created = function () {
        var _this = this;
        gapi.client.load('youtube', 'v3', function () {
            var options = {
                part: 'id,snippet',
                maxResults: 20
            };
            var request = gapi.client.youtube.membershipsLevels.list(options);
            request.execute(function (response) {
                console.log('==== Memberships Levels List ====');
                console.log(response);
                console.log('==== /Memberships Levels List ====');
                _this.json = {
                    request: 'gapi.client.youtube.membershipsLevels.list',
                    options: options,
                    result: response.result
                };
                _this.result = response.result != null ? response.result.items || [] : [];
            });
        });
    };
    MembershipsLevels = __decorate([
        Component({
            components: { JsonExportButton: JsonExportButton }
        })
    ], MembershipsLevels);
    return MembershipsLevels;
}(Vue));
export default MembershipsLevels;
