var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import JsonExportButton from '~/js/components/atomos/JsonExportButton.vue';
import { dateFormat } from '~/js/modules/DateOperation';
var AgeGroup = /** @class */ (function (_super) {
    __extends(AgeGroup, _super);
    function AgeGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.start = '2021-01-01';
        _this.today = '2021-12-01';
        _this.results = [];
        _this.json = null;
        return _this;
    }
    Object.defineProperty(AgeGroup.prototype, "startDate", {
        get: function () {
            return dateFormat(this.start, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AgeGroup.prototype, "endDate", {
        get: function () {
            return dateFormat(this.today, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    AgeGroup.prototype.created = function () {
        var _this = this;
        gapi.client.load('youtubeAnalytics', 'v2', function () {
            var reports = gapi.client.youtubeAnalytics.reports.query({
                ids: 'channel==MINE',
                startDate: dateFormat(_this.start, 'yyyy-MM-01'),
                endDate: dateFormat(_this.today, 'yyyy-MM-01'),
                dimensions: 'ageGroup,gender',
                metrics: 'viewerPercentage'
            });
            reports.execute(function (response) {
                console.log('==== Age Group, Gender ====');
                console.log(response);
                console.log('==== /Age Group, Gender ====');
                _this.json = response.result;
                _this._ready(response.rows);
                // this._ready([
                //   ['age18-24', 'female', 0.1],
                //   ['age18-24', 'male', 28.3],
                //   ['age25-34', 'female', 4.2],
                //   ['age25-34', 'male', 45.6],
                //   ['age35-44', 'male', 21.8]
                // ])
            });
        });
    };
    AgeGroup.prototype._ready = function (res) {
        var d = [];
        res.forEach(function (r) {
            var idx = d.findIndex(function (item) { return item.age === r[0]; });
            if (idx == -1) {
                var _d = ['', ''];
                if (r[1] === 'female') {
                    _d[0] = r[2] + "%";
                    _d[1] = '0%';
                }
                else {
                    _d[0] = '0%';
                    _d[1] = r[2] + "%";
                }
                d.push({
                    age: r[0],
                    female: _d[0],
                    male: _d[1]
                });
            }
            else {
                if (r[1] === 'female') {
                    d[idx].female = r[2] + "%";
                }
                else {
                    d[idx].male = r[2] + "%";
                }
            }
        });
        this.results = d;
    };
    AgeGroup = __decorate([
        Component({
            components: { JsonExportButton: JsonExportButton },
            filters: {
                age: function (key) {
                    switch (key) {
                        case 'age13-17':
                            return '13歳〜17歳';
                        case 'age18-24':
                            return '18歳〜24歳';
                        case 'age25-34':
                            return '25歳〜34歳';
                        case 'age35-44':
                            return '35歳〜44歳';
                        case 'age45-54':
                            return '45歳〜54歳';
                        case 'age65-':
                            return '65歳以上';
                        default:
                            return '';
                    }
                }
            }
        })
    ], AgeGroup);
    return AgeGroup;
}(Vue));
export default AgeGroup;
