import { DateTime } from 'luxon';
export var dateFormat = function (date, format) {
    return DateTime.fromISO(date).toFormat(format);
};
export var monthView = function (date) {
    return dateFormat(date, 'yyyy年MM月');
};
export var dateView = function (date) {
    return dateFormat(date, 'yyyy年MM月dd日');
};
