var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("メンバーシップ情報")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "members" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.status === "forbidden"
          ? [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("メンバーシップが解禁されていません"),
              ]),
            ]
          : _vm.status === "success"
          ? [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.result, function (item) {
                    return _c("tr", { key: item.snippet.creatorChannelId }, [
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: item.snippet.memberDetails.channelUrl,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(item.snippet.memberDetails.displayName)
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("img", {
                          attrs: {
                            src: item.snippet.memberDetails.profileImageUrl,
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(
                              item.snippet.membershipsDetails
                                .membershipsDuration.memberSince
                            )
                          )
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("名前")]),
        _vm._v(" "),
        _c("th", [_vm._v("サムネ")]),
        _vm._v(" "),
        _c("th", [_vm._v("登録日")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }