var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("YouTube Channel Information")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "channel-information" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", [_vm._v("TODO：チャンネルリストはサブチャンネルも取れる様子")]),
    _vm._v(" "),
    _c("div", { staticClass: "pt-3" }, [
      _c("div", [_vm._v("チャンネル名：" + _vm._s(_vm.name))]),
      _vm._v(" "),
      _c("div", [_vm._v("チャンネル説明： " + _vm._s(_vm.description))]),
      _vm._v(" "),
      _c("div", [
        _vm._v("URL："),
        _vm.url
          ? _c(
              "a",
              {
                attrs: {
                  href: "https://www.youtube.com/c/" + _vm.url,
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(_vm.url))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("サムネイル："),
        _vm.thumb
          ? _c("img", { attrs: { src: _vm.thumb.url, alt: "" } })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("アップロード動画数： " + _vm._s(_vm.movies)),
        _c("br"),
        _vm._v("チャンネル登録者数： " + _vm._s(_vm.subscription)),
        _c("br"),
        _vm._v("総動画再生数：" + _vm._s(_vm.total)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }