var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("スーパーチャットイベント")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "super-chat-events-p2" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "mb-0" }, [
      _vm._v("過去30日間固定でしか取れない模様"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.status === "forbidden"
          ? [_c("p", { staticClass: "mb-0" }, [_vm._v("収益化が通ってません")])]
          : _vm.status === "success"
          ? [
              _vm.result.length > 0
                ? [
                    _c("table", { staticClass: "table" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.result, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [_vm._v(_vm._s(item.snippet.channelId))]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.snippet.supporterDetails
                                      .channelUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.snippet.supporterDetails.displayName
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(item.snippet.commentText)),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(item.snippet.displayString)),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(item.snippet.messageType)),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                : [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v("スパチャもらってないようです"),
                    ]),
                  ],
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("チャンネルID")]),
        _vm._v(" "),
        _c("th", [_vm._v("サポーター名")]),
        _vm._v(" "),
        _c("th", [_vm._v("コメント")]),
        _vm._v(" "),
        _c("th", [_vm._v("スパチャ")]),
        _vm._v(" "),
        _c("th", [_vm._v("スパチャランク")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }