var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import AdData from '~/js/components/templates/AdData.vue';
import AdDataMovie from '~/js/components/templates/AdDataMovie.vue';
import AgeGroup from '~/js/components/templates/AgeGroup.vue';
import Information from '~/js/components/templates/Information.vue';
import LiveBroadcasts from '~/js/components/templates/LiveBroadcasts.vue';
import LiveStreams from '~/js/components/templates/LiveStreams.vue';
import Members from '~/js/components/templates/Members.vue';
import MembershipsLevels from '~/js/components/templates/MembershipsLevels.vue';
import Monthly from '~/js/components/templates/Monthly.vue';
import Ranking from '~/js/components/templates/Ranking.vue';
import RankingComments from '~/js/components/templates/RankingComments.vue';
import RankingLikes from '~/js/components/templates/RankingLikes.vue';
import RankingViews from '~/js/components/templates/RankingViews.vue';
import SuperChatEvents from '~/js/components/templates/SuperChatEvents.vue';
var CLIENT_ID = '100501243801-3s9o07dh630jjspmh647c84fta84dncn.apps.googleusercontent.com';
var OAUTH_SCOPES = [
    'https://www.googleapis.com/auth/youtube',
    'https://www.googleapis.com/auth/youtube.readonly',
    'https://www.googleapis.com/auth/yt-analytics.readonly',
    'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
    'https://www.googleapis.com/auth/youtubepartner',
    'https://www.googleapis.com/auth/youtube.channel-memberships.creator',
    'https://www.googleapis.com/auth/youtube.force-ssl'
];
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.channelID = null;
        return _this;
    }
    App.prototype.created = function () {
        var _this = this;
        gapi.load('client', function () {
            gapi.auth.init(function () {
                window.setTimeout(function () {
                    gapi.auth.authorize({
                        client_id: CLIENT_ID,
                        scope: OAUTH_SCOPES,
                        // discoveryDocs: DISCOVERY_DOCS,
                        immediate: true
                    }, _this._handleAuthResult);
                }, 1);
            });
        });
    };
    /**
     * 認証チェック
     * @param authResult
     * @private
     */
    App.prototype._handleAuthResult = function (authResult) {
        var _this = this;
        if (authResult && !authResult.error) {
            // 認証済み
            gapi.client.load('youtube', 'v3', function () {
                var request = gapi.client.youtube.channels.list({
                    mine: true,
                    part: 'id'
                });
                request.execute(function (response) {
                    _this.channelID = response.items[0].id;
                });
            });
        }
        else {
            // 認証していなkればOAUTH画面を表示
            gapi.auth.authorize({
                client_id: CLIENT_ID,
                scope: OAUTH_SCOPES,
                immediate: false
            }, this._handleAuthResult);
        }
    };
    App = __decorate([
        Component({
            components: {
                MembershipsLevels: MembershipsLevels,
                RankingLikes: RankingLikes,
                RankingViews: RankingViews,
                RankingComments: RankingComments,
                SuperChatEvents: SuperChatEvents,
                LiveStreams: LiveStreams,
                LiveBroadcasts: LiveBroadcasts,
                AdDataMovie: AdDataMovie,
                AdData: AdData,
                Ranking: Ranking,
                AgeGroup: AgeGroup,
                Monthly: Monthly,
                Members: Members,
                Information: Information
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
