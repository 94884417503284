export var columnName = function (key) {
    switch (key) {
        case 'month':
            return { name: '月', unit: '' };
        case 'views':
            return { name: '視聴数', unit: 'Views' };
        case 'redViews':
            return { name: 'YouTube Premium<br>メンバーの視聴数', unit: 'Views' };
        case 'likes':
            return { name: '高評価', unit: 'Likes' };
        case 'dislikes':
            return { name: '低評価', unit: 'Dislikes' };
        case 'comments':
            return { name: 'コメント数', unit: 'Comments' };
        case 'estimatedRedMinutesWatched':
            return {
                name: 'YouTube Premium<br>メンバーの装視聴分数',
                unit: 'min'
            };
        case 'averageViewPercentage':
            return { name: '動画再生中の<br>平均視聴率', unit: '%' };
        case 'shares':
            return { name: '共有ボタンからシェアした数', unit: 'Shares' };
        case 'annotationImpressions':
            return { name: 'アノテーション表示回数', unit: '回' };
        case 'estimatedMinutesWatched':
            return { name: '平均視聴時間', unit: 'min' };
        case 'subscribersGained':
            return { name: 'チャンネル登録', unit: '人' };
        case 'subscribersLost':
            return { name: 'チャンネル登録解除', unit: '人' };
        default:
            return { name: '', unit: '' };
    }
};
