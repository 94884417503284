var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import JsonExportButton from '~/js/components/atomos/JsonExportButton.vue';
import { columnName } from '~/js/modules/ColumnName';
import { dateFormat, monthView } from '~/js/modules/DateOperation';
/**
 * チャンネルレポート
 * @see {@link https://developers.google.com/youtube/reporting/guides/authorization/client-side-web-apps#callinganapi}
 */
var Monthly = /** @class */ (function (_super) {
    __extends(Monthly, _super);
    function Monthly() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.start = '2021-01-01';
        _this.today = '2021-12-01';
        _this.results = [];
        _this.headers = [];
        _this.json = null;
        return _this;
    }
    Object.defineProperty(Monthly.prototype, "startDate", {
        get: function () {
            return dateFormat(this.start, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Monthly.prototype, "endDate", {
        get: function () {
            return dateFormat(this.today, 'yyyy年MM月');
        },
        enumerable: false,
        configurable: true
    });
    Monthly.prototype.created = function () {
        var _this = this;
        gapi.client.load('youtubeAnalytics', 'v2', function () {
            var options = {
                ids: 'channel==MINE',
                startDate: dateFormat(_this.start, 'yyyy-MM-01'),
                endDate: dateFormat(_this.today, 'yyyy-MM-01'),
                dimensions: 'month',
                metrics: 'views,likes,dislikes,comments,subscribersGained,subscribersLost'
            };
            var reports = gapi.client.youtubeAnalytics.reports.query(options);
            reports.execute(function (response) {
                console.log('==== Monthly ====');
                console.log(response);
                console.log('==== /Monthly ====');
                _this.json = {
                    request: 'gapi.client.youtubeAnalytics.reports.query',
                    options: options,
                    result: response.result
                };
                _this._ready(response.result);
            });
        });
    };
    Monthly.prototype._ready = function (data) {
        var columnHeaders = data.columnHeaders, rows = data.rows;
        var arr = [];
        var columns = [];
        var headers = [];
        rows.forEach(function (d, i) {
            var child = [];
            d.forEach(function (item, j) {
                if (i === 0) {
                    var header = columnHeaders[j];
                    var r = __assign(__assign({}, columnName(header.name)), { key: header.name });
                    columns.push(r);
                    headers.push(r.name);
                }
                var column = columns[j];
                var tmp = column.key === 'month' ? monthView(item) : item;
                child.push([tmp, column.unit].join(' '));
            });
            arr.push(child);
        });
        this.results = arr;
        this.headers = headers;
    };
    Monthly = __decorate([
        Component({
            components: { JsonExportButton: JsonExportButton }
        })
    ], Monthly);
    return Monthly;
}(Vue));
export default Monthly;
