var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "apps-header" },
      [
        _c("h2", [_vm._v("メンバーシップレベル")]),
        _vm._v(" "),
        _c("json-export-button", {
          attrs: { json: _vm.json, filename: "memberships-levels" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pt-4" },
      [
        _vm.status === "forbidden"
          ? [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("メンバーシップが解禁されていません"),
              ]),
            ]
          : _vm.status === "success"
          ? [
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.result, function (item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [_vm._v(_vm._s(item.snippet.creatorChannelId))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(item.snippet.levelDetails.displayName)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("チャンネルID")]),
        _vm._v(" "),
        _c("th", [_vm._v("名前")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }